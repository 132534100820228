import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import CamMicSettings from "./CamMicSettings";
import StudioDetails from "./StudiDetails";
import queryString from "query-string";
import axios from "axios";
import {baseURL, endPoint} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import {addStudioData, updateStudioData} from "../../actions/studioAction";
import {useHistory} from "react-router-dom";
import {setAlert} from "../../actions/alert";


const Studio = ({location}) => {

    const id = queryString.parse(location.search).id

    const history = useHistory();
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const studioData = useSelector(state => state.studio);

    const [hostStream, setHostStream] = useState({stream:false,name:''});

    const [openStudioDetails, setOpenStudioDetails] = useState(false);

    let studio = null;
    if(openStudioDetails){
        studio = <StudioDetails hostStream={hostStream}/>
    }
    else{
        studio = <CamMicSettings setHostStream={setHostStream} setOpenStudioDetails={setOpenStudioDetails} />
    }

    useEffect(()=>{
        if(id){
            axios({
                method: 'POST',
                url: `${baseURL}fetch-broadcast`,
                data: {id},
                headers: {'Content-Type': 'application/json','Authorization': auth.token}
            }).then(res=>{
                if(res.data.status === true){
                    if(res.data.data.roomId === ''){
                        axios({
                            method: 'GET',
                            url: endPoint,
                            headers: {'Content-Type': 'application/json'}
                        }).then(res => {
                            if (res.status === 200) {
                                const roomId = res.data.room_id;
                                updateStudioData('room_id',roomId);
                                dispatch({type: 'UPDATE_ROOM_ID', payload: roomId})
                            }
                        }).catch(err => {
                            console.log(err);
                        })
                    }
                    dispatch(addStudioData(res.data.data))
                }else{
                    dispatch(setAlert('Unauthorized access to this broadcast', 'danger'));
                    history.push('/broadcasts');
                }
            }).catch(err=>{

            })
        }else{
            history.push('/broadcast');
        }
    },[id])


    return(
        <>
            <Helmet>
                <title>StreamReel Studio | Stream</title>
            </Helmet>

            <Navbar/>
            {studio}
            <Footer/>
        </>
    )
}

export default Studio;