import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrophone, faMicrophoneSlash, faUser, faVideo, faVideoSlash} from "@fortawesome/free-solid-svg-icons";
import liveVideo from "../../../images/profile.jpg";

const PeerUser = ({peer, addPeerStream,userVideoAudio,handlePeerActive,addFeed}) => {
    const videoRef = useRef(null);
    const cp = userVideoAudio[peer.userName];

    useEffect(() => {
        peer.on('stream', (stream) => {
            console.log('checking stream',stream.getVideoTracks())
            videoRef.current.srcObject = stream;
            addPeerStream({
                id:peer.peerID,
                stream:stream,
                name:peer.userName
            })

        });
        peer.on('track', (track, stream) => {
            console.log('checking tack',stream.getVideoTracks());


        });
    }, [peer]);

    const handleActive = (status) => {
        handlePeerActive(peer.userName,status)
    }
    return (
        <li className="go-live-box-footer-group">
            <div className="go-live-box-footer-group-box">
                {/* <div*/}
                {/*    className="go-live-box-footer-group-box-options d-flex justify-content-between">*/}
                {/*    <button type="button" className="btn btn-light" >*/}
                {/*        <FontAwesomeIcon icon={faMicrophoneSlash}> </FontAwesomeIcon>*/}
                {/*    </button>*/}

                {/*    <button type="button" className="btn btn-light" >*/}
                {/*        <FontAwesomeIcon  icon={faVideoSlash}> </FontAwesomeIcon>*/}
                {/*    </button>*/}

                {/*</div>*/}

                <div className="go-live-box-footer-group-box-img">
                    <video ref={videoRef} style={{width:'160px'}} playsInline
                           autoPlay muted/>
                </div>

                <div className="go-live-box-footer-group-box-name d-flex">
                    <span className="icon">
                        <FontAwesomeIcon icon={faUser}> </FontAwesomeIcon>
                    </span>
                    <p>{peer.userName}</p>
                </div>

            </div>

             <div className='addStbtn' >{cp !== undefined && cp.status ? <button onClick={() => handleActive(false)}>Remove from Stream</button> : <button onClick={() => handleActive(true)}>Add To Stream</button>}</div>
        </li>
    );
};

export default PeerUser;
