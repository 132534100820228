import React, {useEffect, useRef, useState} from 'react';
import {VideoStreamMerger} from "video-stream-merger";
import { memo } from "react";
import profileImage from "../../../images/profile.jpg";
let merger = false;
const Preview_5 = ({userVideoAudio, hostStream, peers, setFinalStream, totalFeeds, peersStream}) => {
    const camRef = useRef(null);
    const screenRef = useRef(null);
    const profileRef = useRef(null);
    const profileCanvasRef = useRef(null);

    const [showVideo, setShowVideo] = useState(0);
    const [peerIndex, setPeerIndex] = useState(false);

    const [feedIndex, setFeedIndex] = useState(-1)


    useEffect(() => {
        totalFeeds.forEach((feed,index) => {
            if(feed.status){
                setFeedIndex(index);
            }
        })
    },[JSON.stringify(totalFeeds)])



    useEffect(() => {
        if(userVideoAudio.localUser.status){
            if(userVideoAudio.localUser.video && hostStream !== false){
                setShowVideo(1)
            }else{
                setShowVideo(0)
            }
        }else{
            for (const key in userVideoAudio) {
                if (userVideoAudio.hasOwnProperty(key) && key !== 'localUser') {
                    const peer = userVideoAudio[key];
                    if(peer.status){
                        setShowVideo(2)
                        setPeerIndex(key)
                    }
                }
            }
        }

    },[JSON.stringify(userVideoAudio)])



    useEffect(() => {
        if(merger !== false){
            merger.destroy();
        }
        const stream = new MediaStream();
        merger = new VideoStreamMerger({width:1280,height:720});
        let camStream = false;

        if(showVideo === 1){
            // show user video
            camRef.current.srcObject = hostStream !== false ? hostStream : stream;
            camStream = hostStream !== false ? hostStream : stream;
        }
        if(showVideo === 2){
            // show peer video
            if(peerIndex){
                const lp = peersStream.find(p => p.name === peerIndex)
                if(lp !== undefined){
                    camRef.current.srcObject = lp.stream;
                    camStream = lp.stream;
                }

            }
        }
        if(showVideo === 0 && profileCanvasRef !== null ){
            // show Image
            const canvas = profileCanvasRef.current;
            const context = canvas.getContext("2d");
            context.drawImage(profileRef.current, 0, 0, 250, 150);
            camStream = canvas.captureStream();
        }

        // Adding Cam stream

        merger.addStream(camStream, {
            x: 0,
            y: merger.height/4,
            width: merger.width/4,
            height: merger.height/4,

        })


        if(feedIndex > -1){
            const feed = totalFeeds[feedIndex];
            if(feed.feedType === 'screen'){
                screenRef.current.srcObject = feed.stream
                merger.addStream(feed.stream, {
                    x: merger.width/4,
                    y: 0,
                    width: merger.width - merger.width/4,
                    height: merger.height - merger.height/4,
                })
            }
            if(feed.feedType === 'video'){
                screenRef.current.src = feed.stream.url
                merger.addMediaElement('videoElement',screenRef.current, {
                    x: merger.width/4,
                    y: 0,
                    width: merger.width - merger.width/4,
                    height: merger.height - merger.height/4
                })
            }
        }

        merger.start();
        setFinalStream(merger.result)
    },[showVideo,feedIndex])

    useEffect(() => {
        return () => {
            if(merger !== false){
                merger.destroy();
            }
        }
    },[])

    return (
        <>
        <div className='vidPre5'>
            <div className='vidPre5-left'>{showVideo === 1 || showVideo === 2 ? showVideo === 1 ? <video ref={camRef} autoPlay playsInline muted/> :<video ref={camRef} autoPlay playsInline /> :
                <>
                    <div style={{display:'none'}}>
                        <canvas ref={profileCanvasRef} />
                    </div>
                    <img ref={profileRef} className='defaultImg' src={profileImage} alt="Profile Image" style={{width:'100%'}}/>
                </>
            } </div>}
            <div className='vidPre5-left screen'><video ref={screenRef} autoPlay playsInline /></div>
        </div>
        </>
    );
};

export default memo(Preview_5);
