import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDesktop, faMicrophone, faMicrophoneSlash, faVideoSlash, faUserPlus, faVideo, faUpload} from "@fortawesome/free-solid-svg-icons";

import closeModalx from "../../../images/closeModal.svg"
import {useDispatch, useSelector} from "react-redux";
import {feURL} from "../../../global/global";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import swal from "sweetalert";
import { baseURL } from "../../../global/global";
import axios from "axios";

const CameraSettings = ({addFeed, removeFeed}) => {
    const dispatch = useDispatch();
    const auth = useSelector(state=> state.auth);
    const studio = useSelector(state => state.studio);
    const [showModal, setShowModal] = useState(false);
    const [copy, setCopy] = useState(false);

    const [localUser, setlocalUser] = useState({
        audio:studio.host.audio,
        video:studio.host.video
    })


    const openPop = () => {
        setShowModal(true);
    }
    const closePopUp = () => {
        setShowModal(false);
    }

    const screenRecord = async() => {
        const a =  await navigator.mediaDevices.getDisplayMedia({
            audio: true,
            width:1280,
            height:720,
            video: { mediaSource: "screen"}
        });
        a.getVideoTracks()[0].addEventListener('ended', () => removeFeed())

        return a
    }

    const handleScreenShare = async () => {
        const screenStream = await screenRecord();
        addFeed({
            stream:screenStream,
            hostType:'self',
            feedType:'screen',
            name:'Screen Feed',
            mute:false,
            stopCamp:false,
            status:true
        })
    }

    const handleMute = (status) => {
        dispatch({type: 'UPDATE_AUDIO_STATUS', payload: status})
    }

    const handleStopCam = (status) => {
        dispatch({type: 'UPDATE_VIDEO_STATUS', payload: status})
    }

    const handleFileChange = (e) => {

        if(e.target.files[0]){

            if(e.target.files[0].type === 'video/mp4') {
                let allowedSize = 400 * 1024 * 1024;

                if (e.target.files[0].size < allowedSize) {
                    let blobObj = {
                        visible: false,
                        url: URL.createObjectURL(e.target.files[0]),
                        name: e.target.files[0].name.split('.')[0]
                    }
                    
                    dispatch({type: 'UPDATE_BLOB_VIDEO', payload: blobObj});

                    const form = new FormData();
                    form.append('broadcast_id', studio.id);
                    form.append('video', e.target.files[0]);

                    axios({
                        method: 'POST',
                        url: `${baseURL}blob-file-upload`,
                        data: form,
                        headers: {'Content-Type': 'application/json','Authorization': auth.token}
                    }).then(res=>{
                        console.log('file uploaded');
                    }).catch(err=>{

                    })

                }else{
                    swal("Oops!", "Max allowed size for video 100MB");
                }
            }else{
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    useEffect(()=>{
        setlocalUser({
            ...localUser,
            audio:studio.host.audio,
            video:studio.host.video
        })
    },[studio.host.audio,studio.host.video])


    return (
        <>
        <div className="col-12">
            <div className="go-live-box-options">
                <ul>
                    <li>
                        {localUser.audio === true ? <button type="button" className="btn btn-light live-options-btn" onClick={() => handleMute(false)}>
                            <span className="icon">
                                <FontAwesomeIcon icon={faMicrophone}> </FontAwesomeIcon>
                            </span>
                            Mute
                        </button> : <button type="button" className="btn btn-light live-options-btn" onClick={() => handleMute(true)}>
                            <span className="icon">
                                <FontAwesomeIcon icon={faMicrophoneSlash}> </FontAwesomeIcon>
                            </span>
                            UnMute
                        </button>}

                    </li>
                    <li>
                        {localUser.video === true ? <button onClick={() => handleStopCam(false)} type="button" className="btn btn-light live-options-btn">
                            <span className="icon">
                                <FontAwesomeIcon icon={faVideo}> </FontAwesomeIcon>
                            </span>
                            Stop Cam
                        </button> : <button type="button" onClick={() => handleStopCam(true)} className="btn btn-light live-options-btn">
                            <span className="icon">
                                <FontAwesomeIcon icon={faVideoSlash}> </FontAwesomeIcon>
                            </span>
                            Start Cam
                        </button>}

                    </li>
                    <li>
                        <button type="button" className="btn btn-light live-options-btn" onClick={handleScreenShare}>
                            <span className="icon">
                                <FontAwesomeIcon icon={faDesktop}> </FontAwesomeIcon>
                            </span>
                            Share Screen
                        </button>
                    </li>
                    <li>
                        <button type="button" className="btn btn-light live-options-btn" data-toggle="modal" data-target="#myModal" onClick={(e)=>openPop()}>
                            <span className="icon">
                                <FontAwesomeIcon icon={faUserPlus}> </FontAwesomeIcon>
                            </span>
                            Invite
                        </button>
                    </li>
                    <li>
                        <button type="button" className="btn btn-light live-options-btn" style={{position: 'relative'}}>
                            <span className="icon">
                                <FontAwesomeIcon icon={faUpload}> </FontAwesomeIcon>
                            </span>
                            <input onChange={(e)=>handleFileChange(e)} className="hidden-input" type="file"/>
                            Add Video
                        </button>
                    </li>
                </ul>
            </div>
        </div>


        <div className={`commonModal commonModal-sm modal ${showModal ? 'show-modal' : ''} `}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    
                        {/* <h5 className="modal-title"></h5> */}
                        <button onClick={(e)=>closePopUp('')} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <img src={closeModalx} />
                        </button>
                    
                    <div className="modal-body">
                        <div className="broadcast-content">
                            <div className="box-form-control">
                                <h2 className="popTitle">Invite Guests To Join Broadcast</h2>
                                <div className="form-group">
                                    <label for="inputTitle">Share the URL to your guest</label>
                                    <input readOnly={true} type="text" className="form-control" id="inputTitle" placeholder="URL" value={`${feURL}guest-studio?id=${studio.id}`} />
                                </div>
                                <div className="">
                                    <CopyToClipboard text={`${feURL}guest-studio?id=${studio.id}`} onCopy={() => setCopy(true)}>
                                        <button className="btn btn-block btn-link btn-gradient-orange border-50 popBtn">{copy?'Copied':'Copy'} to Clipboard</button>
                                    </CopyToClipboard>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        </>
    );
};

export default CameraSettings;
