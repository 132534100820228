import React, {useEffect, useRef, useState} from 'react';
import {Col, Nav, Row, Tab, Dropdown} from "react-bootstrap";
import Broadcast from "./Broadcast/Broadcast";
import Music from "./Music/Music";
import Banner from "./Banner/Banner";
import Customize from "./Customize/Customize";
import Comments from "./Comment/Comments";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import {
    faAngleDoubleRight,
    faFilm,
    faMicrophone, faUser,
    faVideo
} from "@fortawesome/free-solid-svg-icons";
import Chat from "./Chat/Chat";
import CameraSettings from "./CameraSettings/CameraSettings";
import Presets from "./Presets/Presets";
import StudioNavigation from "./StudioNavigation/StudioNavigation";
import TimelineUsers from "./TimelineUsers/TImelineUsers";
import {useDispatch, useSelector} from "react-redux";
import socket from "../../global/socketExitConnection";
import {VideoStreamMerger} from "video-stream-merger";
import SimplePeer from "simple-peer";
import Preview_1 from "./Preview/Preview_1";
import Preview_2 from "./Preview/Preview_2";
import Preview_3 from "./Preview/Preview_3";
import Preview_4 from "./Preview/Preview_4";
import Preview_5 from "./Preview/Preview_5";
import Preview_6 from "./Preview/Preview_6";
import BackgroundCanvas from "./Preview/BackgroundCanvas";
import LogoCanvas from "./Preview/LogoCanvas";
import OverlayCanvas from "./Preview/OverlayCanvas";
import BannerCanvas from "./Preview/BannerCanvas";
import PeerUser from "./TimelineUsers/PeerUser";
import SelfUser from "./TimelineUsers/SelfUser";
import StudioMusic from "./StudioMusic";
import VideoPreview from "./Preview/VideoPreview";


let mediaRecorder = false;
let peer = false;
let localStream = false;
let merger = false;

const StudioDetails = ({hostStream}) => {
    localStream = hostStream.stream;
    const  history = useHistory();
    const studioData = useSelector(state => state.studio);

    const dispatch = useDispatch();

    const [userVideoAudio, setUserVideoAudio] = useState({
        localUser: { video: studioData.host.video, audio: studioData.host.audio, status:true },
    });

    const [totalFeeds, setTotalFeeds] = useState([]);

    const [peers, setPeers] = useState([]);
    const peersRef = useRef([]);
    const mergedPreviewRef = useRef(null);


    const [previewType, setPreviewType] = useState(1);

    const [finalStream, setFinalStream] = useState(false);
    const [mergedStream, setMergedStream] = useState(localStream);

    const [peersStream, setPeersStream] = useState([]);

    const [customization, setCustomization] = useState({
        color:false,
        textType:'default',
        logo:{
            enable:false,
            src:'',
            stream:false
        },
        overlay:{
            enable:false,
            src:'',
            stream:false
        },
        background:{
            enable:false,
            src:'',
            stream:false
        },
        video:{
            enable:false,
            src:'',
            stream:false
        },
        banner:{
            enable:false,
            src:'',
            stream:false
        }

    })




    // Add Feed screen share and video
    const addFeed = (feedObject) => {
        if(feedObject.feedType === 'screen' || 'video'){
            if(feedObject.hostType === 'self'){
                setPreviewType(6)
            }
        }
        setTotalFeeds([...totalFeeds,feedObject]);
    }

    // Remove Feed
    const removeFeed = () => {
        //const feedObject = totalFeeds.filter(item => item.hostType === 'self' && item.feedType === 'screen');
        setTotalFeeds(totalFeeds);
        setPreviewType(1)
    }

    // Enable / Disable Feed
    const enableDisableFeed = (feedIndex,status) => {
        const newFeed = [...totalFeeds];
        // console.log(newFeed,'Enable Disable Feed',feedIndex,status)
        newFeed[feedIndex].status = status;
        setTotalFeeds(newFeed);
    }

    // Go Live & end Live
    const handleLive = (type) => {
        if(studioData.live.enable === false) {
            const payload = {
                type:type,
                enable:true
            }
            dispatch({type: 'UPDATE_LIVE_STATUS', payload: payload})
        }else{
            const payload = {
                type:type,
                enable:false
            }
            dispatch({type: 'UPDATE_LIVE_STATUS', payload: payload})
            socket.emit("streamEnd", {broadcastId: studioData.id, broadcastType: type});
        }
    }

    // will fire on exit studio click
    const handleExitStudio = () => {
        // stop the camera and mic
        totalFeeds.forEach(feed => {
            if(feed.stream !== false){
                if(feed.stream.hasOwnProperty('url')){

                }
                else{
                    feed.stream.getTracks().forEach(track => {console.log('Exiting Studio'); track.stop()})
                }

            }
        })

        if(hostStream.stream !== false) {
            hostStream.stream.getTracks().forEach(track => {console.log('Exiting Studio'); track.stop()})
        }

        // emit end studio
        socket.emit("streamEnd", {broadcastId: studioData.id, broadcastType: studioData.live.type});
        socket.disconnect();

        // update redux data
        const payload = {...studioData.live,enable:false}
        dispatch({type: 'UPDATE_LIVE_STATUS', payload: payload })

        // redirect to broadcast page
        history.push('/broadcasts')
    }

    // add a new Peer
    function addPeer(incomingSignal, callerId, stream, name) {
        console.log('Adding Peer',callerId)
        const peer = new SimplePeer({
            initiator: false,
            trickle: false,
            stream,
        });

        peer.on('signal', (signal) => {
            socket.emit('BE-accept-call', { signal, to: callerId });
        });

        peer.on('disconnect', () => {
            peer.destroy();
        });

        peer.on('stream', (stream) => {
            console.log(stream,'streaming host side')
            addPeerStream({
                id:callerId,
                stream:stream,
                name:name
            })

        });

        peer.signal(incomingSignal);

        return peer;
    }

    // add peer stream
    function addPeerStream(peerObject) {
        setPeersStream([...peersStream,peerObject])
    }

    // find Peer Index
    function findPeer(id) {
        return peersRef.current.find((p) => p.peerID === id);
    }

    // create a new Peer
    function createPeer(userToCall, caller, stream) {
        console.log('Creating Peer',userToCall, caller, stream)
        const peer = new SimplePeer({
            initiator: true,
            trickle: false,
            stream,
        });

        peer.on('signal', (signal) => {
            socket.emit('BE-call-user', {
                userToCall: userToCall,
                from: caller,
                signal:signal,
            });
        });

        peer.on('disconnect', () => {
            peer.destroy();
        });

        return peer;
    }

    // peer activate / deactivate
    const handlePeerActive = (peerUsername,status) => {
        setUserVideoAudio((preList) => {
            return {
                ...preList,
                [peerUsername]: { ...userVideoAudio[peerUsername],status:status },
            };
        });
    }

    // Load the Preview Type Component
    const previewTypeComponent = () => {
        switch(previewType) {
            case 1:
                return <Preview_1 peersStream={peersStream} userVideoAudio={userVideoAudio} hostStream={localStream} peers={peers} setFinalStream={setFinalStream}/>;
            case 2:
                return <Preview_2 peersStream={peersStream} userVideoAudio={userVideoAudio} hostStream={localStream}  setFinalStream={setFinalStream}/>;
            case 3:
                return <Preview_3 peersStream={peersStream} userVideoAudio={userVideoAudio} hostStream={localStream}  setFinalStream={setFinalStream}/>;
            case 4:
                return <Preview_4 peersStream={peersStream} userVideoAudio={userVideoAudio} hostStream={localStream}  setFinalStream={setFinalStream}/>;
            case 5:
                return <Preview_5 peersStream={peersStream} userVideoAudio={userVideoAudio} hostStream={localStream} peers={peers} totalFeeds={totalFeeds} setFinalStream={setFinalStream}/>;
            case 6:
                return <Preview_6 totalFeeds={totalFeeds} setFinalStream={setFinalStream}/>;
            default:
                return <Preview_1 userVideoAudio={userVideoAudio} hostStream={localStream} peers={peers} setFinalStream={setFinalStream}/>;
        }
    }

    // will fire once video is uploaded
    useEffect(() => {
        if(studioData.blobVideo.length > 0){
            const newFeed = [...totalFeeds];
            studioData.blobVideo.forEach(video =>  {
                newFeed.push({
                    stream:video,
                    hostType:'self',
                    feedType:'video',
                    name:video.name,
                    mute:false,
                    stopCam:false,
                    status:false
                })
            })
            setTotalFeeds(newFeed);
        }
    },[JSON.stringify(studioData.blobVideo)])


    // will fire once user is muted / stopCamp
    useEffect(() =>{
        if(localStream !== false){
            localStream.getAudioTracks()[0].enabled = studioData.host.audio;
            localStream.getVideoTracks()[0].enabled = studioData.host.video;
        }
        setUserVideoAudio((preList) => {
            return {
                ...preList,
                localUser: { ...userVideoAudio.localUser, video:studioData.host.video, audio:studioData.host.audio },
            };
        });
    },[studioData.host.audio,studioData.host.video])


    // will fire on studio unmount
    useEffect(() => {
        return () => {
            // emit for unmount component
            socket.emit("streamEnd", {broadcastId: studioData.id, broadcastType: studioData.live.type});
            socket.disconnect();

            const payload = {...studioData.live,enable:false}
            dispatch({type: 'UPDATE_LIVE_STATUS', payload: payload })

            totalFeeds.forEach(feed => {
                if(feed.stream !== false){
                    if(feed.stream.hasOwnProperty('url')){

                    }
                    else{
                        feed.stream.getTracks().forEach(track => {console.log('Exiting Studio'); track.stop()})
                    }

                }
            })

            if(hostStream.stream !== false) {
                hostStream.stream.getTracks().forEach(track => {console.log('Exiting Studio'); track.stop()})
            }

            if(merger !== false){
                merger.destroy();
            }

        };
    }, []);

    // final stream merging
    useEffect(() => {

        if(finalStream !== false){
            console.log('Rendering Streaming ');
            // if(mediaRecorder !== false){
            //
            //     if(mediaRecorder.state === 'recording'){
            //         mediaRecorder.stop();
            //
            //     }
            // }
            if(merger !== false){
                merger.destroy();
            }
            merger = new VideoStreamMerger({width:1280,height:720})
            // check if there is a background Image
            if(customization.background.enable === true && customization.background.stream !== false){
                merger.addStream(customization.background.stream, {
                    x: 0,
                    y: 0,
                    width: merger.width,
                    height: merger.height,
                    mute: true
                })
            }

            merger.addStream(finalStream, {
                x: 0,
                y: 0,
                width: merger.width,
                height: merger.height
            })


            // check if there is logo Image
            if(customization.logo.enable === true && customization.logo.stream !== false){
                merger.addStream(customization.logo.stream, {
                    x: merger.width-100,
                    y: 0,
                    width: 100,
                    height: 100,
                    mute: true,
                })
            }

            // Banner Customization Data
            if(customization.banner.enable === true && customization.banner.stream !== false){
                merger.addStream(customization.banner.stream, {
                    x: 0,
                    y: merger.height-40,
                    width: merger.width,
                    height: 40,
                    mute: true,
                })
            }

            // check if there is  Overlay Image
            if(customization.overlay.enable === true && customization.overlay.stream !== false){
                merger.addStream(customization.overlay.stream, {
                    x: 0,
                    y: 0,
                    width: merger.width,
                    height: merger.height,
                    mute: true
                })
            }

            // check if there is a video added
            if(customization.video.enable === true && customization.video.stream !== false){
                merger.addMediaElement('videoPrElement',customization.video.stream, {
                    x: 0,
                    y: 0,
                    width: merger.width,
                    height: merger.height,
                    mute: true
                })
            }


            merger.start();
            if(studioData.live.enable){
                socket.emit('destroyChildProcess');
                socket.emit('createChildProcess');
            }

            let mediaRecorder_1 = new MediaRecorder(merger.result, { audioBitsPerSecond: 100000, videoBitsPerSecond: 4000000,mimeType: "video/webm;codecs=vp8,opus"});


            mediaRecorder_1.addEventListener('dataavailable', (e) => {
                if(studioData.live.enable) {
                    console.log('Sending Data',e);
                    socket.emit('message',e.data);
                }
            });

            mediaRecorder_1.addEventListener('error', (event) => {
                console.error(`error recording stream: ${event.error.name}`)
            });

            // mediaRecorder.addEventListener('stop', socket.close.bind(io));
            mediaRecorder_1.start(100); // Start recording, and dump data every second
            setMergedStream(merger.result);
        }

    },[finalStream,customization,studioData.live.type,studioData.live.enable])


    useEffect(() => {

    },[])


    useEffect(() => {

        if(studioData.live.enable){
            console.log('Going Live');
            socket.emit('goLive',{'broadcastId':studioData.id,'broadcastType':studioData.live.type})
        }
    },[studioData.live.enable])


    // socket connection
    useEffect(() => {
        console.log('Call Room');
        socket.emit("joinRoom",{roomId:studioData.roomId, userName:hostStream.name});

        socket.on('FE-user-join', (users) => {
            console.log('on Join users', users);
            // all users
            const peers = [];
            users.forEach((user) => {
                let { userName, video, audio,socketId} = user.info;

                if (userName !== hostStream.name) {
                    console.log(userName,hostStream.name,'condition')
                    const peer = createPeer(socketId, socket.id, mergedStream);

                    peer.userName = userName;
                    peer.peerID = socketId;

                    peersRef.current.push({
                        peerID: socketId,
                        peer,
                        userName,
                    });
                    peers.push(peer);

                    console.log('FE-user-join Received');
                    setUserVideoAudio((preList) => {
                        return {
                            ...preList,
                            [peer.userName]: { video, audio,status:false },
                        };
                    });

                }
                else{
                    console.log('user already exists')
                }
            });

            setPeers(peers);
        });

        socket.on('FE-receive-call', ({ signal, from, info }) => {
            console.log('FE-receive-call',from, info)
            let { userName, video, audio } = info;
            const peerIdx = findPeer(from);

            if (!peerIdx) {
                const peer = addPeer(signal, from, mergedStream, userName);

                peer.userName = userName;

                peersRef.current.push({
                    peerID: from,
                    peer,
                    userName: userName,
                });
                setPeers((users) => {
                    return [...users, peer];
                });
                setUserVideoAudio((preList) => {
                    return {
                        ...preList,
                        [peer.userName]: { video, audio,status:false },
                    };
                });
            }
        });


        socket.on('FE-call-accepted', ({ signal, answerId }) => {
            console.log('Call Accepted',answerId,signal);
            const peerIdx = findPeer(answerId);
            peerIdx.peer.signal(signal);
            peerIdx.peer.on('stream',(stream) => {
                console.log(stream,'checking stream')
            })
        });


        socket.on('FE-user-leave', ({ userId, userName }) => {
            const peerIdx = findPeer(userId);
            peerIdx.peer.destroy();
            setPeers((users) => {
                users = users.filter((user) => user.peerID !== peerIdx.peer.peerID);
                return [...users];
            });
            peersRef.current = peersRef.current.filter(({ peerID }) => peerID !== userId );
        });


        socket.on('FE-toggle-camera', ({ userId, switchTarget }) => {
            const peerIdx = findPeer(userId);

            setUserVideoAudio((preList) => {
                let video = preList[peerIdx.userName].video;
                let audio = preList[peerIdx.userName].audio;

                if (switchTarget === 'video') video = !video;
                else audio = !audio;

                return {
                    ...preList,
                    [peerIdx.userName]: { video, audio },
                };
            });
        });

    },[])


    // This is for testing
    useEffect(() => {
        if(mergedStream){
            if(peer){
                peer.addStream(mergedStream);
            }
            // mergedRef.current.srcObject = mergedStream;
            // mergedRef.current.play().then(res => console.log('Playing Final result',res)).catch(error => console.log('Error playing',error))
        }
        // mergedPreviewRef.current.srcObject = mergedStream
    }, [mergedStream]);
    return (
        <>
            <div className="warraperEd">
                <section className="edit-page">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="edit-page-container">
                            <div className="row">
                                <div className="col-12 col-md-3 p-md-0 col-chat-left">
                                    <div className="edit-page-box">
                                        <Row>
                                            <Col lg={12} md={12} xs={12}>
                                                <Tab.Content className="edit-page-tab">
                                                    <Broadcast/>
                                                    <Music/>
                                                    <Banner/>
                                                    <Customize streamCustomization={customization} setStreamCustomization={setCustomization}/>
                                                    <Comments/>
                                                </Tab.Content>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                                <div className="col-12 col-md-9 p-md-0 col-chat-right">
                                    <div className="go-live-box">
                                        <div className="go-live-box-top">
                                            <div className="container-fluid">
                                                <div className="row justify-content-end">
                                                    <div className="col-12 col-md-6">
                                                        <div className="go-live-box-top-row d-flex  justify-content-end">
                                                            <div className="go-live-box-top-row-button d-flex">
                                                                <div className='goliveDrop'>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="btn btn btn-link btn-gradient-orange btn-xs-small" id="dropdown-basic">
                                                                    <span className="icon mr-2">
                                                                        <FontAwesomeIcon icon={faFilm}> </FontAwesomeIcon>
                                                                    </span>
                                                                        {studioData.live.enable === true ? studioData.live.type === 'live' ? 'Live' : 'Recording'  : 'Go Live' }
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <h5>Ready to Go Live?</h5>
                                                                        <div className='row pt-3'>
                                                                            <div className='col-6'>
                                                                                {
                                                                                    studioData.live.enable === true && studioData.live.type === 'record' ?
                                                                                        <button className='btn-gradient-orange alt btn-block' onClick={()=>handleLive('record')}>End Recording</button>
                                                                                    :
                                                                                        <button className='btn-gradient-orange alt btn-block' onClick={()=>handleLive('record')}>Record Only</button>
                                                                                }
                                                                            </div>
                                                                            <div className='col-6'>
                                                                                {
                                                                                    studioData.live.enable === true && studioData.live.type === "live" ?
                                                                                        <button className='btn-gradient-orange btn-block' onClick={()=>handleLive('live')}>End live</button>
                                                                                    :
                                                                                        <button className='btn-gradient-orange btn-block' onClick={()=>handleLive('live')}>Go live</button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                </div>

                                                                <button onClick={handleExitStudio} className="btn btn btn-link btn-gradient-orange btn-xs-small" type="submit">
                                                                    Exit Studio
                                                                    <span className="icon ml-2">
                                                                        <FontAwesomeIcon icon={faAngleDoubleRight}> </FontAwesomeIcon>
                                                                    </span>
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="editTabNav">
                                            <StudioNavigation />
                                        </div>

                                        <div className="go-live-box-body">
                                            <div className="go-live-box-options">
                                                <div className="row justify-content-center">
                                                    <CameraSettings addFeed={addFeed} removeFeed={removeFeed}/>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-10 mx-auto text-center">
                                                    <div className="scale-video-wrap">
                                                        <div className="scale-video preview-bg">
                                                            <BackgroundCanvas customization={customization} setCustomization={setCustomization} />
                                                            {previewTypeComponent()}
                                                            <LogoCanvas customization={customization} setCustomization={setCustomization} />
                                                            <OverlayCanvas customization={customization} setCustomization={setCustomization} />
                                                            <div className='BannerCanvas'><BannerCanvas customization={customization} setCustomization={setCustomization} /></div>
                                                            <VideoPreview customization={customization} setCustomization={setCustomization} />

                                                            {/* for play selected music on studio */}
                                                            <StudioMusic music={studioData.music} />



                                                        </div>
                                                    </div>
                                                    {/*<Preview addFeed={addFeed} totalFeeds={totalFeeds} previewType={previewType} streamCustomization={customization}/>*/}
                                                    {/*<video ref={mergedPreviewRef} autoPlay playsInline className="preview_merged" width="688" height="387"/>*/}
                                                    <Presets setPreviewType={setPreviewType} previewType={previewType}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="go-live-box-footer">
                                            <div className="row justify-content-center">
                                                <div className="col-12">
                                                    <div className="go-live-box-footer-row">
                                                        <ul>
                                                            <SelfUser stream={localStream} localUser={userVideoAudio.localUser} name={hostStream.name} handlePeerActive={handlePeerActive}/>
                                                            {peers.map((peer,index) => <PeerUser addPeerStream={addPeerStream} handlePeerActive={handlePeerActive} peer={peer}  userVideoAudio={userVideoAudio} key={index} addFeed={addFeed}/>)} ) }
                                                            {totalFeeds.map((feed,index) => <TimelineUsers feedIndex={index} key={index} feed={feed} enableDisableFeed={enableDisableFeed}/>)}

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Tab.Container>
                </section>
            </div>

            {/*<Chat/>*/}
        </>
    );
};

export default StudioDetails;
