import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrophone, faUser, faVideo, faMicrophoneSlash,faVideoSlash} from "@fortawesome/free-solid-svg-icons";
import liveVideo from "../../../images/profile.jpg";

const TimelineUsers = ({feed, enableDisableFeed, feedIndex}) => {
    const videoRef = useRef(null);
    const [localFeed, setLocalFeed] = useState(feed);
    useEffect(() => {
        if(feed.stream !== false && feed.feedType === 'screen'){
            videoRef.current.srcObject = feed.stream;
        }
        if(feed.stream !== false && feed.feedType === 'video'){
            videoRef.current.src = feed.stream.url;
        }
        setLocalFeed(feed)

    },[feed.stream,feed.mute,feed.stopCam,feed.name,feed.hostType,feed.feedType])

    const handleStreamClick = (status) =>{
        enableDisableFeed(feedIndex,status)
    }

    const handlePer = (type,status) => {

    }

    return (
        <li className="go-live-box-footer-group"> 
            <div className="go-live-box-footer-group-box">
                {localFeed.feedType === 'camera' ? <div
                    className="go-live-box-footer-group-box-options d-flex justify-content-between">
                    {localFeed.mute === true ? <button type="button" className="btn btn-light" onClick={() => handlePer('mute',false)}>
                        <FontAwesomeIcon icon={faMicrophoneSlash}> </FontAwesomeIcon>
                    </button> : <button type="button" className="btn btn-light" onClick={() => handlePer('mute',true)}>
                        <FontAwesomeIcon icon={faMicrophone}> </FontAwesomeIcon>
                    </button>}

                    {localFeed.stopCam === true ? <button type="button" className="btn btn-light" onClick={() => handlePer('stopCam',false)}>
                        <FontAwesomeIcon  icon={faVideoSlash}> </FontAwesomeIcon>
                    </button> : <button type="button" className="btn btn-light" onClick={() => handlePer('stopCam',true)}>
                        <FontAwesomeIcon  icon={faVideo}> </FontAwesomeIcon>
                    </button>}

                </div> : null}

                <div className="go-live-box-footer-group-box-img">
                    {localFeed.stream !== false ? <video ref={videoRef} style={{width:'160px'}} autoPlay playsInline muted/> : <img src={liveVideo} alt="live video" className="img-fluid"/>}
                </div>

                <div className="go-live-box-footer-group-box-name d-flex">
                    <span className="icon">
                        <FontAwesomeIcon icon={localFeed.feedType === 'camera' ? faUser : faVideo}> </FontAwesomeIcon>
                    </span>
                    <p>{localFeed.name}</p>
                </div>

            </div>
            {
                localFeed.status === true ? <div className='addStbtn' onClick={() => handleStreamClick(false)} ><button>Remove From Stream</button></div> : <div className='addStbtn' onClick={() => handleStreamClick(true)}><button>Add To Stream</button></div>
            }


        </li>
    );
};

export default TimelineUsers;
