import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {Tab} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {updateStudioData} from "../../../actions/studioAction";
import randomstring from "randomstring";
import Alert from "../../Alert";
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons";

const Banner = () => {

    const dispatch = useDispatch();
    const bannerState = useSelector(state => state.studio.banner);

    const [loader, setLoader] = useState(false);
    const [bannerData, setBannerData] = useState([]);
    const [banner, setBanner] = useState({
        id: randomstring.generate({length: 10, charset: 'numeric'}),
        enable: false,
        head: '',
        sub_head: ''
    })

    const handleInputChange = (e) => {

        if(e.target.value.length < 80)
            setBanner({...banner, [e.target.name]: e.target.value})
    }

    const handleBannerStatus = (id, status) => {
        let tempData = [...bannerState];

        let tempBanner =  tempData.map(item=>(
            item.id === id ? {...item, enable: status} : {...item, enable: false}
        ))

        dispatch({type: 'UPDATE_BANNER_STATUS', payload: tempBanner});
    }


    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        let bannerArr = [...bannerData];
        banner.id = randomstring.generate({length: 10, charset: 'numeric'});
        bannerArr.push(banner)

        dispatch(updateStudioData('banner', bannerArr, setLoader));
        setBanner({
            ...banner,
            head: '',
            sub_head: ''
        })
    }


    useEffect(()=>{
        console.log(bannerState.length,'banner length')
        setBannerData(bannerState)
    },[bannerState.length])

    return (
        <Tab.Pane eventKey="third">
            <Alert/>
            <h2 className="tabTitle alt">Banner</h2>
            <div className="broadcast-content">
                <form method="post" onSubmit={(e)=>handleFormSubmit(e)} className="content-box content-box--broadcast box-form-control purple-box text-white p-3 mb-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label for="inputTitle">Heading
                                    <span className="icon skyblue" title="Add some text to your broadcast">
                                        <FontAwesomeIcon icon={faQuestionCircle}> </FontAwesomeIcon>
                                    </span>
                                </label>
                                <input required value={banner.head} type="text" className="form-control" name="head"
                                       id="inputTitle" placeholder="Add Heading (max 80 characters allowed)" onChange={(e)=>handleInputChange(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="go-live-box-top-row-button">
                        <button disabled={loader} className="btn btn btn-link btn-gradient-orange btn-add-more border-50 ml-0" type="submit">
                            { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Add
                        </button>
                    </div>
                </form>

                {
                    bannerState.length > 0 ?
                        bannerState.map((item,index)=>(
                           <div className={`bannerAddBlock mb-4 circle-img-wrap active ${item.enable?'activated':''}`} key={index}>
                               <h2>{item.head}</h2>
                               {
                                   item.enable ?
                                       <div className="circle-img-disable" onClick={()=>handleBannerStatus(item.id,false)}>
                                           <span><FontAwesomeIcon icon={faEyeSlash}> </FontAwesomeIcon></span>
                                           <span>Hide</span>
                                       </div>
                                   :
                                       <div className="circle-img-disable" onClick={()=>handleBannerStatus(item.id,true)}>
                                           <span><FontAwesomeIcon icon={faEye}> </FontAwesomeIcon></span>
                                           <span>Show</span>
                                       </div>
                               }
                           </div>
                       ))
                   : ''
                }

            </div>

        </Tab.Pane>
    );
};

export default React.memo(Banner);
