import React, {useEffect, useRef, useState} from 'react';
import {VideoStreamMerger} from "video-stream-merger";
import { memo } from "react";
let merger = false;
const Preview_2 = ({userVideoAudio, hostStream, setFinalStream, peersStream}) => {
    const videoRef_1 = useRef(null);
    const videoRef_2 = useRef(null);
    const [activePeers, setActivePeers] = useState([]);
    // console.log('PeerStream',peersStream[0].stream.getTracks())
    useEffect(() => {
        const active = []
        for (const key in userVideoAudio) {
            if (userVideoAudio.hasOwnProperty(key)) {
                const peer = userVideoAudio[key];
                if(peer.status){
                    active.push(key)
                }
            }
        }
        setActivePeers(active)

    },[JSON.stringify(userVideoAudio)])

    useEffect(() => {
        if(merger !== false){
            merger.destroy();
        }
        merger = new VideoStreamMerger({width:1280,height:720})
        const stream = new MediaStream();
        merger.addStream(stream, {
            x: 0,
            y: 0,
            width: 1280,
            height: 720
        })

            // add first peer
            if(activePeers[0] !== undefined){
                const peer_1 = activePeers[0];
                let peer_1_stream = false
                if(peer_1 === 'localUser'){
                    videoRef_1.current.srcObject = hostStream !== false ? hostStream : stream;
                    peer_1_stream = hostStream !== false ? hostStream : stream;
                }
                else{
                    const peerFound = peersStream.find(p => p.name === peer_1);
                    if(peerFound !== undefined){
                        videoRef_1.current.srcObject = peerFound.stream;
                        peer_1_stream = peerFound.stream;
                    }
                }
                merger.addStream(peer_1_stream, {
                    x: 0,
                    y: 0,
                    width: merger.width/2,
                    height: merger.height,
                })
            }else{
                videoRef_1.current.srcObject = stream;
            }



            // add second peer
            if(activePeers[1] !== undefined){
                const peer_2 = activePeers[1];
                let peer_2_stream = false;
                if(peer_2 === 'localUser'){
                    videoRef_2.current.srcObject = hostStream;
                    peer_2_stream = hostStream;
                }
                else{
                    const peerFound = peersStream.find(p => p.name === peer_2);
                    if(peerFound !== undefined){
                        videoRef_2.current.srcObject = peerFound.stream;
                        peer_2_stream = peerFound.stream;
                    }
                }

                merger.addStream(peer_2_stream, {
                    x: merger.width/2,
                    y: 0,
                    width: merger.width/2,
                    height: merger.height,
                })
            }else{
                videoRef_2.current.srcObject = stream;
            }



        merger.start();
        setFinalStream(merger.result)


    },[activePeers])

    useEffect(() => {
        return () => {
            if(merger !== false){
                merger.destroy();
            }
        }
    },[])

    return (
        <>
            <div className='vidPre5 videoFullheight'>
                <div className='vidPre5-left'><video ref={videoRef_1} autoPlay playsInline/></div>
                <div className='vidPre5-left screen'><video ref={videoRef_2} autoPlay playsInline/></div>
            </div>
        </>
    );
};

export default memo(Preview_2);
